/* .container{
    background-image: url("../pics/samurai-7785853_1920.jpg");
        background-position: 'center';
        background-size: 'cover';
        background-repeat: 'no-repeat';
} */

h2{
    color: white;
    text-align: center;
    
}

.bg-success{
    max-width: 100%;
    overflow-x: hidden;
    /* background-image: url("../pics/alli-remler-fDr810hjco8-unsplash.jpg"); */
    background-image: linear-gradient(red, yellow);
    /* background-position: 'center'; */
    background-size:200%;
    background-repeat: 'no-repeat';
    background-position: center;
}

.bg-failed{
    max-width: 100%;
    overflow-x: hidden;
    /* background-image: url("../pics/michelle-francisca-lee-A8GdQjQ40SQ-unsplash.jpg"); */
    background-image: linear-gradient(red, yellow);
    /* background-position: 'center'; */
    background-size: 200%;
    background-repeat: 'no-repeat';
    background-position: center;
}

.bg-payment{
    max-width: 100%;
    overflow-x: hidden;
    /* background-image: url("../pics/sonder-quest-70SWYI4w9Rc-unsplash.jpg"); */
    background-image: linear-gradient(red, yellow);
    /* background-position: 'center'; */
    background-size: 150%;
    background-repeat: 'no-repeat';
    background-position: center;
}

.bg-sales-channel{
    max-width: 100%;
    overflow-x: hidden;
    /* background-image: url("../pics/sonder-quest-70SWYI4w9Rc-unsplash.jpg"); */
    background-image: linear-gradient(red, yellow);
    /* background-position: 'center'; */
    background-size: 150%;
    background-repeat: 'no-repeat';
    background-position: center;
}

.box-right {
    padding: 30px 25px;
    background-color: white;
    border-radius: 15px;
    min-height: 80vh;
}

.box-right-payment{
    padding: 30px 25px;
    background-color: white;
    border-radius: 15px;
}

.box-sales-channel-list{
    padding: 30px 25px;
    background-color: white;
    border-radius: 15px;
}

.center {
    /* display: flex; */
    justify-content: center;
    align-items: end;
    text-align: center;
  }

.font-center{
    justify-content: center;
    align-items: end;
    height: 100px;
}

.gif-logo{
    justify-content: center;
    align-items: center;
    height: 150px;
}  

.failed-logo {
    color: red;
    height: 100px;
}

.failed-status{
    padding-left: 10px;
    height: 50px;
    line-height: 30px;
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    color: #c01919;
    font-family: sans-serif;
}

.failed-createdAt{
    padding-top: 15px;
    color: rgb(156, 172, 156);
    font-size: 15px;
}

.failed-text{
    height: 150px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.failed-logo {
    padding-top: 20px;
}

.img{
    width: 150px;
    height: 150px;
}

.success-logo {
    height: 150px;
}

.success-status{
    padding-left: 10px;
    height: 100px;
    line-height: 30px;
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    color: #00b909;
    font-family: sans-serif;
    align-items: center;
}

.success-amount-label-center{
    justify-content: center;
    align-items: end;
    height: 50px;
    text-align: center;
}

.success-createdAt{
    height: 15px;
    color: rgb(156, 172, 156);
    font-size: 15px;
}

.success-amount{
    padding-top: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.table{
    text-align: center;
}

.transactionId{
    font-size: 15px;
    color: rgb(156, 172, 156);
    /* height: 50px; */
    justify-content: center;
    align-items: end;
    text-align: center;
}

.success-amount-text{
    font-size: 50px;
}
