@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  /* background-color: #ffdd8d !important */
  max-height: 100vh;
  height: 100vh;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}


.container {
    max-width: 900px;
    margin: 30px auto;
    padding: 35px;
}

.box-right {
    padding: 30px 25px;
    background-color: white;
    border-radius: 15px
}

@media(max-width:320px) {
    ::placeholder {
        font-size: 12px
    }
}

.logoname {
  padding-left: 10px;
  height: 60px;
  line-height: 60px;
  font-size: 33px;
  font-weight: 700;
  display: table-cell;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  vertical-align: middle;
  color: #191a20;
  font-family: sans-serif;
}

.App-header .container {
  padding: 0px;
}

.App-header .logogroup{
  padding: 10px;
  background-color: white;
}

.secondary-color{
  color: rgb(245, 140, 0) ;
}

.fas.fa-store.secondary-color{
  font-size: 18px;
}